<template>
  <div class="idx-middle-box3">
    <div class="idx-middle-1">人口概况</div>
    <div class="idx-middle-2" @click="go">
      <!-- <div class="idx-middle-2a">
        <span>{{ getdatavalue(244) }}</span>
        <span>总人口</span>
      </div> -->
      <div class="idx-middle-2a">
        <span>{{ getdatavalue(245) }}</span>
        <span>户籍人口</span>
      </div>
      <div class="idx-middle-2a">
        <span>{{ getdatavalue(246) }}</span>
        <span>常住人口</span>
      </div>
      <div class="idx-middle-2b">
        <div class="idx-middle-2d-t">
          <span>本月迁入总人数</span>
          <span class="rk-s1">{{ getdatavalue(247) }}</span>
          <span>人，迁出</span>
          <span class="rk-s1">{{ getdatavalue(248) }}</span>
          <span>人</span>
        </div>
        <div class="idx-middle-2d-b">
          <span>本月出生总人数</span>
          <span class="rk-s1">{{ getdatavalue(249) }}</span>
          <span>人，死亡</span>
          <span class="rk-s1">{{ getdatavalue(250) }}</span>
          <span>人</span>
        </div>
      </div>
    </div>
    <div class="idx-middle-3">
      <div class="idx-middle-3-L" @click="go1">
        <ul>
          <li>
            <div>
              <span class="idx-s3">独生子女</span>
              <span>{{ getdatavalue(251) }}</span>
              <span class="idx-s3">人</span>
            </div>
            <div class="sq-box">独生子女父母奖励金：{{ getdatavalue(307) }}元/人</div>
          </li>
          <li>
            <div>
              <span class="idx-s3">失独老人</span>
              <span>{{ getdatavalue(252) }}</span>
              <span class="idx-s3">对</span>
            </div>
            <div class="sq-box">政府补贴{{ getdatavalue(308) }}元/月</div>
          </li>
          <li>
            <div>
              <span class="idx-s3 idx-s3a">{{ getdatavalue(253) }}</span>
              <span>/</span>
              <span>{{ getdatavalue(254) }}</span>
            </div>
            <div class="sq-box">低保户（户数/人数）</div>
          </li>
          <li>
            <div>
              <span class="idx-s3 idx-s3a">{{ getdatavalue(255) }}</span>
              <span>/</span>
              <span>{{ getdatavalue(256) }}</span>
              <span class="idx-s3b">%</span>
            </div>
            <div class="sq-box">优抚对象（人数/就业率）</div>
          </li>
          <li>
            <div>
              <span class="idx-s3 idx-s3a">{{ getdatavalue(257) }}</span>
              <span>/</span>
              <span>{{ getdatavalue(258) }}</span>
            </div>
            <div class="sq-box">低收入（户数/人数）</div>
          </li>
          <li>
            <div>
              <span class="idx-s3 idx-s3a">{{ getdatavalue(259) }}</span>
            </div>
            <div class="sq-box">残疾人士</div>
          </li>
        </ul>
      </div>
      <div class="idx-middle-3-R" @click="go2">
        <div class="idx-middle-3-R-T">退伍军人</div>
        <div class="idx-middle-3-R-B">
          <div class="shequ-1">
            <div class="shequ-1a">残疾军人</div>
            <div class="shequ-1b">{{ getdatavalue(260) }}</div>
          </div>
          <div class="shequ-1">
            <div class="shequ-1a">军队转业干部</div>
            <div class="shequ-1b">{{ getdatavalue(261) }}</div>
          </div>
          <div class="shequ-1">
            <div class="shequ-1a">复员军人</div>
            <div class="shequ-1b">{{ getdatavalue(262) }}</div>
          </div>
          <div class="shequ-1">
            <div class="shequ-1a">三红</div>
            <div class="shequ-1b">{{ getdatavalue(263) }}</div>
          </div>
          <div class="shequ-1">
            <div class="shequ-1a">退役士兵</div>
            <div class="shequ-1b">{{ getdatavalue(264) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listData, getvalue } from "@/api/dtdata";
export default {
  components: {},
  props: ['dialog_click'],
  data() {
    return {
      dataOptions: [],
      queryParams: {
        dataModule: "rkgk",
        dataTyp: "rkgk_rkgk",
      },
    };
  },
  components: {},
  computed: {},
  created() {
    listData(this.queryParams).then((response) => {
      this.dataOptions = response.rows;
    });
  },

  methods: {
    go() {
      let arr = [
        { dataId: '245',label: "户籍人口",dataDetailed: this.getdatavalue(245) },
        { dataId: '246',label: "常住人口",dataDetailed: this.getdatavalue(246) },
        { dataId: '247',label: "本月迁入人数",dataDetailed: this.getdatavalue(247) },
        { dataId: '248',label: "本月迁出人数",dataDetailed: this.getdatavalue(248) },
        { dataId: '249',label: "本月出生人数",dataDetailed: this.getdatavalue(249) },
        { dataId: '250',label: "本月死亡人数",dataDetailed: this.getdatavalue(250) },
      ]
      this.$emit("dialog_click","人口概念",arr);
    },
    go1() {
      let arr = [
        { dataId: '251',label: "独生子女",dataDetailed: this.getdatavalue(251) },
        { dataId: '307',label: "独生子女父母奖励金",dataDetailed: this.getdatavalue(307) },
        { dataId: '252',label: "失独老人",dataDetailed: this.getdatavalue(252) },
        { dataId: '308',label: "政府补贴",dataDetailed: this.getdatavalue(308) },
        { dataId: '253',label: "低保户(户数)",dataDetailed: this.getdatavalue(253) },
        { dataId: '254',label: "低保户(人数)",dataDetailed: this.getdatavalue(254) },
        { dataId: '255',label: "优抚对象(人数)",dataDetailed: this.getdatavalue(255) },
        { dataId: '256',label: "优抚对象(就业率)",dataDetailed: this.getdatavalue(256) },
        { dataId: '257',label: "低收入(户数)",dataDetailed: this.getdatavalue(257) },
        { dataId: '258',label: "低收入(人数)",dataDetailed: this.getdatavalue(258) },
        { dataId: '259',label: "残疾人士",dataDetailed: this.getdatavalue(259) },
      ]
      this.$emit("dialog_click","人口概念",arr);
    },
    go2() {
      let arr = [
        { dataId: '260',label: "残疾军人",dataDetailed: this.getdatavalue(260) },
        { dataId: '261',label: "军队转业干部",dataDetailed: this.getdatavalue(261) },
        { dataId: '262',label: "复员军人",dataDetailed: this.getdatavalue(262) },
        { dataId: '263',label: "三红",dataDetailed: this.getdatavalue(263) },
        { dataId: '264',label: "退役士兵",dataDetailed: this.getdatavalue(264) },
      ]
      this.$emit("dialog_click","退伍军人",arr);
    },
    getdatavalue(id) {
      return getvalue(this.dataOptions, id);
    },
  },
};
</script>

<style scoped lang="scss">
.idx-middle-box3 {
  width: 100%;
  height: 45vh;
  overflow: hidden;
  //   border: 1px solid tan;
  .idx-middle-1 {
    width: 100%;
    height: 6vh;
    line-height: 6vh;
    text-align: center;
    font-size: 0.9vw;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffd901;
  }
  .idx-middle-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 2vh;
    .idx-middle-2a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      line-height: 3vh;
      span {
        &:first-child {
          font-size: 1.3vw;
          font-family: hy;
          font-weight: bold;
          font-style: italic;
          color: #ffd901;
        }
        &:last-child {
          font-size: 0.7vw;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #eeab1a;
        }
      }
    }
    .idx-middle-2b {
      font-size: 0.7vw;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #ffffff;
      line-height: 3vh;
      .rk-s1 {
        font-size: 1.2vw;
        font-family: hy;
        font-weight: bold;
        font-style: italic;
        color: #ffd901;
        padding: 0 1vw;
      }
    }
  }
  .idx-middle-3 {
    width: 100%;
    height: 30vh;
    overflow: hidden;
    // border: 1px solid tan;
    .idx-middle-3-L {
      width: 57%;
      height: 30vh;
      overflow: hidden;
      //   border: 1px solid tan;
      float: left;
      padding-top: 2vh;
      ul li {
        width: 48%;
        height: 8.5vh;
        line-height: 2.6vh;
        //   border: 1px solid teal;
        float: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        div {
          span {
            font-size: 1.2vw;
            font-family: hy;
            font-weight: bold;
            font-style: italic;
            color: #fff7c8;
            padding-right: 0.5vw;

            // &:first-child {
            //   font-size: 1.2vw;
            //   font-family: hy;
            //   font-weight: bold;
            //   font-style: italic;
            //   color: #fff7c8;
            //   padding-right: 0.5vw;
            // }
            // &:last-child {
            //   font-size: 0.6vw;
            //   font-weight: bold;
            //   font-style: normal;
            //   color: rgba(4, 243, 238, 1);
            // }
          }
          .idx-s3 {
            font-family: Adobe Heiti Std;
            font-size: 0.8vw;
            font-weight: bold;
            font-style: normal;
            color: rgba(4, 243, 238, 1);
          }
          .idx-s3a {
            font-size: 1.2vw;
            font-family: hy;
            font-style: italic;
          }
          .idx-s3b {
            font-family: Adobe Heiti Std;
            font-size: 0.8vw;
          }
        }
        .sq-box {
          font-size: 0.7vw;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #eeab1a;
        }
      }
    }
    .idx-middle-3-R {
      width: 41%;
      height: 30vh;
      overflow: hidden;
      //   border: 1px solid tan;
      float: left;
      .idx-middle-3-R-T {
        width: 100%;
        height: 9vh;
        line-height: 9vh;
        font-size: 0.9vw;
        padding-left: 1vw;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #05e1ee;
        // border: 1px solid tan;
      }
      .idx-middle-3-R-B {
        width: 88%;
        border: 1px solid rgba(86, 111, 236, 1);
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .shequ-1 {
          width: 100%;
          height: 3vh;
          line-height: 3vh;
          border-bottom: 1px solid rgba(86, 111, 236, 1);
          font-size: 0.7vw;
          color: #fff;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          .shequ-1a {
            width: 60%;
            text-align: center;
            border-right: 1px solid rgba(86, 111, 236, 1);
          }
          .shequ-1b {
            width: 40%;
            text-align: center;
          }
        }
        .shequ-title {
          width: 100%;
          text-align: left;
          padding-left: -12vw;
          display: inline-block;
          padding-left: 3vw;
        }
      }
    }
  }
}
</style>
