<template>
  <div class="idx-middle-box3">
    <div class="idx-middle-1">各小区人口数量</div>
    <div class="idx-middle-2" id="ttBox" @click="go"></div>
  </div>
</template>

<script>
import { listData, getvalue } from "@/api/dtdata";
export default {
  components: {},
  props: ['dialog_click'],
  data() {
    return {
      ydata: [],
      xdata: [],
      dataOptions: [],
      queryParams: {
        dataModule: "rkgk",
        dataTyp: "rkgk_czrkdb",
      },
    };
  },
  components: {},
  computed: {},
  created() {
    listData(this.queryParams).then((response) => {
      for (let i = 0; i < response.rows.length; i++) {
        this.ydata[i] = parseInt(response.rows[i].dataDetailed);
        this.xdata[i] = response.rows[i].dataName;
        // console.log(this.ydata[i].name+':'+this.ydata[i].value);
      }
      this.dataOptions = response.rows
         console.log(this.dataOptions,this.xdata);

      this.xiaoqumenNum();
    });
  },

  methods: {
    go() {
      let arr = this.dataOptions.map(item=>{
        return {
          dataDetailed: item.dataDetailed,
          dataId: item.dataId,
          label: item.dataName
        }
      })
      this.$emit("dialog_click","长者服务",arr);
    },
    // 各小区人口数量
    xiaoqumenNum() {
      let myChart = this.$echarts.init(document.getElementById("ttBox"));
      // 常住人口
      //   let dom = document.getElementById("ttBox");
      //   let myChart = echarts.init(dom);
      let app = {};
      //   let option = null;
      let option = {
        color: ["#3398DB"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },

        xAxis: [
          {
            type: "category",
            data: this.xdata, //["东泰花园", "阳光小区", "凯旋城", "光大花园", "景湖花园", "景湖春晓", "景湖春天", "景湖蓝郡", "景湖名郡", "黄旗印象", "锦绣旗峰", "卡布斯", "汇业大厦", "民盈国贸", "九龙一号", "万科", /*'菁英荟花园',*/ "莞寓", "友谊市场"],
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              color: "#fff",
              fontStyle: "normal",
              interval: 0,
              fontSize: 12,
            },
            //设置轴线的属性
            axisLine: {
              lineStyle: {
                color: "#0079FF",
                width: 1, //这里是为了突出显示加上的
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              color: "#fff",
              fontStyle: "normal",
              fontSize: 12,
            },
            //设置轴线的属性
            axisLine: {
              lineStyle: {
                color: "#0079FF",
                width: 1, //这里是为了突出显示加上的
              },
            },
            splitLine: {
              //网格线
              lineStyle: {
                color: "#666",
                type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
              },
              show: true, //隐藏或显示
            },
          },
        ],
        series: [
          {
            name: "",
            type: "bar",
            barWidth: "30%",
            data: this.ydata, //[
            //   15021,
            //   11336,
            //   6352,
            //   3571,
            //   3531,
            //   10131,
            //   11977,
            //   3560,
            //   4998,
            //   3026,
            //   1289,
            //   1600,
            //   0,
            //   0,
            //   1281,
            //   4872,
            //   //0,
            //   1858,
            //   500,
            // ],
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#fff",
                    fontSize: 12,
                  },
                },
                // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 1, color: "#83B1FF" }, //柱图渐变色
                  { offset: 0.5, color: "#0086FF" }, //柱图渐变色
                  { offset: 0, color: "#0086FF" }, //柱图渐变色
                ]),
              },
            },
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
  mounted() {
    this.xiaoqumenNum();
  },
};
</script>

<style scoped lang="scss">
.idx-middle-box3 {
  width: 100%;
  height: 33vh;
  overflow: hidden;
  //   border: 1px solid tan;
  .idx-middle-1 {
    width: 100%;
    height: 6vh;
    line-height: 6vh;
    text-align: center;
    font-size: 0.9vw;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffd901;
  }
  .idx-middle-2 {
    width: 100%;
    height: 33vh;
    overflow: hidden;
    position: absolute;
    top: -3vh;
    left: 0vw;
    right: 0vw;
  }
}
</style>
