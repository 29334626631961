<template>
  <div class="idx-right-box3">
    <div class="idx-right-1">长者服务</div>
    <div @click="go">
      <div class="idx-right-2">
        60岁以上老年人（户籍 <span>{{ getdatavalue(265) }}</span
        >人，非户籍<span>{{ getdatavalue(266) }}</span
        >人)
      </div>
      <!--长者服务  -->
      <div class="idx-right-3" id="zhangzhe"></div>
    </div>
    <div class="idx-right-4">
      <div class="idx-right-4-L" @click="go1">
        <div class="idx-middle-3-L">
          <ul>
            <li>
              <div>
                <span class="idx-s3 idx-s3a">{{ getdatavalue(267) }}</span>
                <span>/</span>
                <span>{{ getdatavalue(268) }}</span>
              </div>
              <div class="sq-box">办理敬老卡(户籍/非户籍)</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="idx-right-4-R" @click="go2">
        <div class="idx-right-4-R1">高龄补贴</div>
        <div class="idx-right-4-R2">
          <div class="shequ-1">
            <div class="shequ-1a">60-69岁</div>
            <div class="shequ-1b">{{ getdatavalue(274) }}</div>
          </div>
          <div class="shequ-1">
            <div class="shequ-1a">70-79岁</div>
            <div class="shequ-1b">{{ getdatavalue(275) }}</div>
          </div>
          <div class="shequ-1">
            <div class="shequ-1a">80-89岁</div>
            <div class="shequ-1b">{{ getdatavalue(276) }}</div>
          </div>
          <div class="shequ-1">
            <div class="shequ-1a">90-99岁</div>
            <div class="shequ-1b">{{ getdatavalue(277) }}</div>
          </div>
          <div class="shequ-1">
            <div class="shequ-1a">100岁以上</div>
            <div class="shequ-1b">{{ getdatavalue(278) }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 65-69岁 70-79岁 80-89岁 90-99岁 100岁以上-->
    <!-- <div class="idx-right-5"></div> -->
  </div>
</template>

<script>
import { listData, getvalue } from "@/api/dtdata";
export default {
  components: {},
  props: ['dialog_click'],
  data() {
    return {
      ydata: [],
      xdata: [],
      dataOptions: [],
      queryParams: {
        dataModule: "rkgk",
        dataTyp: "rkgk_zzfw",
      },
      queryParams1: {
        dataModule: "rkgk",
        dataTyp: "rkgk_zzfwmx",
      },
    };
  },
  components: {},
  computed: {},
  created() {
    listData(this.queryParams).then((response) => {
      this.dataOptions = response.rows;
    });

    listData(this.queryParams1).then((response) => {
      for (let i = 0; i < response.rows.length; i++) {
        this.ydata[i] = {};
        this.ydata[i].name = response.rows[i].dataName;
        this.ydata[i].value = parseInt(response.rows[i].dataDetailed);
        this.xdata[i] = response.rows[i].dataName;
        // console.log(this.ydata[i].name+':'+this.ydata[i].value);
      }
      //    console.log(this.xdata);

      this.zhangzhe();
    });
  },

  methods: {
    go() {
      let arr = [
        { dataId: '265',label: "60岁以上老年人(户籍)",dataDetailed: this.getdatavalue(265) },
        { dataId: '266',label: "60岁以上老年人(非户籍)",dataDetailed: this.getdatavalue(266) },
      ]
      this.$emit("dialog_click","长者服务",arr);
    },
    go1() {
      let arr = [
        { dataId: '267',label: "办理敬老卡(户籍)",dataDetailed: this.getdatavalue(267) },
        { dataId: '268',label: "办理敬老卡(非户籍)",dataDetailed: this.getdatavalue(268) },
      ]
      this.$emit("dialog_click","办理敬老卡",arr);
    },
    go2() {
      let arr = [
        { dataId: '274',label: "60-69岁",dataDetailed: this.getdatavalue(274) },
        { dataId: '275',label: "70-79岁",dataDetailed: this.getdatavalue(275) },
        { dataId: '276',label: "80-89岁",dataDetailed: this.getdatavalue(276) },
        { dataId: '277',label: "90-99岁",dataDetailed: this.getdatavalue(277) },
        { dataId: '278',label: "100岁以上",dataDetailed: this.getdatavalue(278) },
      ]
      this.$emit("dialog_click","高龄补贴",arr);
    },
    getdatavalue(id) {
      return getvalue(this.dataOptions, id);
    },

    zhangzhe() {
      // 判断pc iPad
      if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
        let myChart = this.$echarts.init(document.getElementById("zhangzhe"));
        //    <!-- 65-69岁 70-79岁 80-89岁 90-99岁 100岁以上-->
        // let xdata = ["盗窃案件", "诈骗案件", "公民求助"];

        // let xdata = ["65-69岁", "70-79岁", "80-89岁", "90-99岁", "100岁以上"];

        // let ydata = [
        //   {
        //     name: "65-69岁",
        //     value: 400,
        //   },
        //   {
        //     name: "70-79岁",
        //     value: 200,
        //   },
        //   {
        //     name: "80-89岁",
        //     value: 100,
        //   },
        //   {
        //     name: "90-99岁",
        //     value: 50,
        //   },

        //   {
        //     name: "100岁以上",
        //     value: 400,
        //   //   // label: {
        //   //     normal: {
        //   //       show: false,
        //   //       position: "center",
        //   //       // formatter: '{text|{b}}\n{c} ({d}%)',
        //   //       formatter: "{text|{b}}\n {value|{c}}",
        //   //       // formatter: '{text|{b}}',
        //   //       rich: {
        //   //         text: {
        //   //           color: "#fff",
        //   //           fontSize: 12,
        //   //           align: "center",
        //   //           verticalAlign: "middle",
        //   //           padding: 8,
        //   //         },
        //   //         value: {
        //   //           color: "#FFFF43",
        //   //           fontSize: 14,
        //   //           align: "center",
        //   //           verticalAlign: "middle",
        //   //         },
        //   //       },
        //   //     },
        //   //     emphasis: {
        //   //       show: true,
        //   //       textStyle: {
        //   //         fontSize: 14,
        //   //       },
        //   //     },
        //   //   },
        //    },
        // ];

        let color = ["#2363F1", "#24D8D7", "#807E00", "#007E80", "#FFDA08"];

        let option = {
          /*backgroundColor: "rgba(255,255,255,1)",*/
          title: {
            //   text: "民生警情",
            subtext: "",
            textStyle: {
              color: "#FFFFFF",
              fontSize: 14, //文字的字体大小
            },
            left: "23%",
            top: "85.5%",
          },
          color: color,
          legend: {
            orient: "vertical",
            top: "0%",
            right: "8%",
            bottom: "0%",
            data: this.xdata,
            itemWidth: 10,
            itemHeight: 10,
            textStyle: {
              //图例文字的样式
              color: "#fff",
              fontSize: 12,
              padding: 0,
            },
            /*itemGap: 14,*/
            /*formatter:function(name){
				  let oa = option.series[0].data;
				  let num = oa[0].value + oa[1].value + oa[2].value + oa[3].value+oa[4].value + oa[5].value + oa[6].value + oa[7].value+oa[8].value + oa[9].value ;
				  for(let i = 0; i < option.series[0].data.length; i++){
				      if(name==oa[i].name){
				          return ' '+name + '    |    ' + oa[i].value + '    |    ' + (oa[i].value/num * 100).toFixed(2) + '%';
				      }
				  }
				}*/

            formatter: function (name) {
              return "" + name;
            },
          },
          series: [
            {
              type: "pie",
              clockwise: false, //饼图的扇区是否是顺时针排布
              minAngle: 2, //最小的扇区角度（0 ~ 360）
              // radius: ["45%", "55%"],
              // center: ["30%", "53%"],
              radius: ["45%", "65%"],
              center: ["20%", "45%"],
              avoidLabelOverlap: false,
              itemStyle: {
                //图形样式
                normal: {
                  // borderColor: "#ffffff",
                  borderWidth: 1,
                },
              },
              label: {
                normal: {
                  show: false,
                  position: "center",
                  formatter: "{text|{b}}\n{c}",
                  // formatter: '{text|{b}}\n {text|{c} ({d}%)}',
                  // formatter: '{text|{b}}\n{c} ({d}%)',
                  rich: {
                    text: {
                      color: "#fff",
                      fontSize: 12,
                      align: "center",
                      verticalAlign: "middle",
                      padding: 8,
                    },
                    value: {
                      color: "#fff",
                      fontSize: 12,
                      align: "center",
                      verticalAlign: "middle",
                    },
                  },
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: 14,
                  },
                },
              },

              data: this.ydata,
            },
          ],
        };
        myChart.setOption(option);

        setTimeout(function () {
          myChart.on("click", function (params) {
            dzbMap(params.name);
          });
          myChart.on("mouseover", function (params) {
            // window.clearInterval(setInterval())
            // clearInterval(qq);
            // if (params.name == this.ydata[0].name) {
            if (params.name == this.ydata[0].name) {
              myChart.dispatchAction({
                type: "highlight",
                seriesIndex: 0,
                dataIndex: 0,
              });
            } else {
              myChart.dispatchAction({
                type: "downplay",
                seriesIndex: 0,
                dataIndex: 0,
              });
            }
          });

          myChart.on("mouseout", function (params) {
            // setInterval(qq)
            myChart.dispatchAction({
              type: "highlight",
              seriesIndex: 0,
              dataIndex: 0,
            });
          });
          myChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: 0,
          });
        }, 3000);

        myChart.currentIndex = -1;

        let qq = setInterval(function () {
          // let dataLen = option.series[0].data.length;
          let dataLen = option.series[0].data.length;
          console.log("dataLen", dataLen);
          // 取消之前高亮的图形
          myChart.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
            dataIndex: myChart.currentIndex,
          });
          myChart.currentIndex = (myChart.currentIndex + 1) % dataLen;
          // 高亮当前图形
          myChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: myChart.currentIndex,
          });
        }, 3000);

        // 使用刚指定的配置项和数据显示图表。
        /*myChart.setOption(option);*/
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      } else {
        let myChart = this.$echarts.init(document.getElementById("zhangzhe"));
        //    <!-- 65-69岁 70-79岁 80-89岁 90-99岁 100岁以上-->
        // let xdata = ["盗窃案件", "诈骗案件", "公民求助"];

        // let xdata = ["65-69岁", "70-79岁", "80-89岁", "90-99岁", "100岁以上"];

        // let ydata = [
        //   {
        //     name: "65-69岁",
        //     value: 400,
        //   },
        //   {
        //     name: "70-79岁",
        //     value: 200,
        //   },
        //   {
        //     name: "80-89岁",
        //     value: 100,
        //   },
        //   {
        //     name: "90-99岁",
        //     value: 50,
        //   },

        //   {
        //     name: "100岁以上",
        //     value: 400,
        //   //   // label: {
        //   //     normal: {
        //   //       show: false,
        //   //       position: "center",
        //   //       // formatter: '{text|{b}}\n{c} ({d}%)',
        //   //       formatter: "{text|{b}}\n {value|{c}}",
        //   //       // formatter: '{text|{b}}',
        //   //       rich: {
        //   //         text: {
        //   //           color: "#fff",
        //   //           fontSize: 12,
        //   //           align: "center",
        //   //           verticalAlign: "middle",
        //   //           padding: 8,
        //   //         },
        //   //         value: {
        //   //           color: "#FFFF43",
        //   //           fontSize: 14,
        //   //           align: "center",
        //   //           verticalAlign: "middle",
        //   //         },
        //   //       },
        //   //     },
        //   //     emphasis: {
        //   //       show: true,
        //   //       textStyle: {
        //   //         fontSize: 14,
        //   //       },
        //   //     },
        //   //   },
        //    },
        // ];

        let color = ["#2363F1", "#24D8D7", "#807E00", "#007E80", "#FFDA08"];

        let option = {
          /*backgroundColor: "rgba(255,255,255,1)",*/
          title: {
            //   text: "民生警情",
            subtext: "",
            textStyle: {
              color: "#FFFFFF",
              fontSize: 14, //文字的字体大小
            },
            left: "23%",
            top: "85.5%",
          },
          color: color,
          legend: {
            orient: "vertical",
            top: "1%",
            right: "8%",
            bottom: "0%",
            data: this.xdata,
            itemWidth: 10,
            itemHeight: 10,
            textStyle: {
              //图例文字的样式
              color: "#fff",
              fontSize: 14,
              padding: 1,
            },
            /*itemGap: 14,*/
            /*formatter:function(name){
				  let oa = option.series[0].data;
				  let num = oa[0].value + oa[1].value + oa[2].value + oa[3].value+oa[4].value + oa[5].value + oa[6].value + oa[7].value+oa[8].value + oa[9].value ;
				  for(let i = 0; i < option.series[0].data.length; i++){
				      if(name==oa[i].name){
				          return ' '+name + '    |    ' + oa[i].value + '    |    ' + (oa[i].value/num * 100).toFixed(2) + '%';
				      }
				  }
				}*/

            formatter: function (name) {
              return "" + name;
            },
          },
          series: [
            {
              type: "pie",
              clockwise: false, //饼图的扇区是否是顺时针排布
              minAngle: 2, //最小的扇区角度（0 ~ 360）
              // radius: ["45%", "55%"],
              // center: ["30%", "53%"],
              radius: ["45%", "65%"],
              center: ["20%", "40%"],
              avoidLabelOverlap: false,
              itemStyle: {
                //图形样式
                normal: {
                  // borderColor: "#ffffff",
                  borderWidth: 1,
                },
              },
              label: {
                normal: {
                  show: false,
                  position: "center",
                  formatter: "{text|{b}}\n{c}",
                  // formatter: '{text|{b}}\n {text|{c} ({d}%)}',
                  // formatter: '{text|{b}}\n{c} ({d}%)',
                  rich: {
                    text: {
                      color: "#fff",
                      fontSize: 14,
                      align: "center",
                      verticalAlign: "middle",
                      padding: 8,
                    },
                    value: {
                      color: "#fff",
                      fontSize: 14,
                      align: "center",
                      verticalAlign: "middle",
                    },
                  },
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: 14,
                  },
                },
              },

              data: this.ydata,
            },
          ],
        };
        myChart.setOption(option);

        setTimeout(function () {
          myChart.on("click", function (params) {
            dzbMap(params.name);
          });
          myChart.on("mouseover", function (params) {
            // window.clearInterval(setInterval())
            // clearInterval(qq);
            // if (params.name == this.ydata[0].name) {
            if (params.name == this.ydata[0].name) {
              myChart.dispatchAction({
                type: "highlight",
                seriesIndex: 0,
                dataIndex: 0,
              });
            } else {
              myChart.dispatchAction({
                type: "downplay",
                seriesIndex: 0,
                dataIndex: 0,
              });
            }
          });

          myChart.on("mouseout", function (params) {
            // setInterval(qq)
            myChart.dispatchAction({
              type: "highlight",
              seriesIndex: 0,
              dataIndex: 0,
            });
          });
          myChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: 0,
          });
        }, 3000);

        myChart.currentIndex = -1;

        let qq = setInterval(function () {
          // let dataLen = option.series[0].data.length;
          let dataLen = option.series[0].data.length;
          console.log("dataLen", dataLen);
          // 取消之前高亮的图形
          myChart.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
            dataIndex: myChart.currentIndex,
          });
          myChart.currentIndex = (myChart.currentIndex + 1) % dataLen;
          // 高亮当前图形
          myChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: myChart.currentIndex,
          });
        }, 3000);

        // 使用刚指定的配置项和数据显示图表。
        /*myChart.setOption(option);*/
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
    },
  },
  mounted() {
    // this.zhangzhe();
    // this.drawChart();
  },
};
</script>

<style scoped lang="scss">
.idx-right-box3 {
  width: 100%;
  height: 45vh;
  overflow: hidden;
  //   border: 1px solid tan;
  .idx-right-1 {
    width: 100%;
    height: 6vh;
    line-height: 6vh;
    text-align: center;
    font-size: 0.9vw;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffd901;
  }
  .idx-right-2 {
    width: 100%;
    height: 6vh;
    line-height: 6vh;
    color: #fff;
    text-align: center;
    font-size: 0.9vw;
    // border: 1px solid tan;
    span {
      font-size: 1.2vw;
      font-family: hy;
      font-weight: bold;
      font-style: italic;
      color: #ffd901;
      padding: 0 0.2vw;
    }
  }
  .idx-right-3 {
    width: 95%;
    height: 15vh;
    color: #fff;
    text-align: center;
    border-bottom: 1px solid rgba(64, 64, 64, 1);
    margin: 0 auto;
  }
  //   .idx-right-4 {
  //     width: 100%;
  //     height: 1vh;
  //     color: #fff;
  //     text-align: center;
  //     border: 1px solid tan;
  //   }
  .idx-right-4 {
    width: 100%;
    height: 15vh;
    color: #fff;
    text-align: center;
    // border: 1px solid teal;
    .idx-right-4-L {
      width: 50%;
      height: 18vh;
      color: #fff;
      text-align: center;
      float: left;
      //   border: 1px solid teal;
      ul li {
        width: 100%;
        height: 17.5vh;
        line-height: 2.6vh;
        //   border: 1px solid teal;
        float: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        div {
          span {
            font-size: 1.2vw;
            font-family: hy;
            font-weight: bold;
            font-style: italic;
            color: #fff7c8;
            padding-right: 0.5vw;

            // &:first-child {
            //   font-size: 1.2vw;
            //   font-family: hy;
            //   font-weight: bold;
            //   font-style: italic;
            //   color: #fff7c8;
            //   padding-right: 0.5vw;
            // }
            // &:last-child {
            //   font-size: 0.6vw;
            //   font-weight: bold;
            //   font-style: normal;
            //   color: rgba(4, 243, 238, 1);
            // }
          }
          .idx-s3 {
            font-family: Adobe Heiti Std;
            font-size: 0.8vw;
            font-weight: bold;
            font-style: normal;
            color: rgba(4, 243, 238, 1);
          }
          .idx-s3a {
            font-size: 1.2vw;
            font-family: hy;
            font-style: italic;
          }
          .idx-s3b {
            font-family: Adobe Heiti Std;
            font-size: 0.8vw;
          }
        }
        .sq-box {
          font-size: 0.7vw;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #eeab1a;
        }
      }
    }
    .idx-right-4-R {
      width: 50%;
      height: 18vh;
      color: #fff;
      text-align: center;
      float: left;
      //   border: 1px solid teal;
      .idx-right-4-R1 {
        width: 100%;
        height: 3vh;
        line-height: 3vh;
        text-align: center;
        color: rgba(4, 243, 238, 1);
        // border: 1px solid teal;
      }
      .idx-right-4-R2 {
        width: 80%;
        border: 1px solid rgba(86, 111, 236, 1);
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .shequ-1 {
          width: 100%;
          height: 2.7vh;
          line-height: 2.7vh;
          border-bottom: 1px solid rgba(86, 111, 236, 1);
          font-size: 0.7vw;
          color: #fff;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          .shequ-1a {
            width: 60%;
            text-align: center;
            border-right: 1px solid rgba(86, 111, 236, 1);
          }
          .shequ-1b {
            width: 40%;
            text-align: center;
          }
        }
        .shequ-title {
          width: 100%;
          text-align: left;
          padding-left: -12vw;
          display: inline-block;
          padding-left: 3vw;
        }
      }
    }
  }
}
</style>
